export default {
  badge: 'Märk',
  close: 'Sulge',
  dataIterator: {
    noResultsText: 'Vastavaid kirjeid ei leitud',
    loadingText: 'Andmeid laaditakse...',
  },
  dataTable: {
    itemsPerPageText: 'Ridu leheküljel:',
    ariaLabel: {
      sortDescending: 'Kahanevalt sorteeritud.',
      sortAscending: 'Kasvavalt sorteeritud.',
      sortNone: 'Ei ole sorteeritud.',
      activateNone: 'Vajuta uuesti sorteerimise eemaldamiseks.',
      activateDescending: 'Vajuta uuesti, et sorteerida kahanevalt.',
      activateAscending: 'Vajuta kasvavalt sorteerimiseks.',
    },
    sortBy: 'Sorteerimise alus',
  },
  dataFooter: {
    itemsPerPageText: 'Kirjeid leheküljel:',
    itemsPerPageAll: 'Kõik',
    nextPage: 'Järgmine lehekülg',
    prevPage: 'Eelmine lehekülg',
    firstPage: 'Esimene lehekülg',
    lastPage: 'Viimane lehekülg',
    pageText: '{0}-{1} {2}st',
  },
  datePicker: {
    itemsSelected: '{0} valitud',
    nextMonthAriaLabel: 'Järgmine kuu',
    nextYearAriaLabel: 'Järgmine aasta',
    prevMonthAriaLabel: 'Eelmine kuu',
    prevYearAriaLabel: 'Eelmine aasta',
  },
  noDataText: 'Andmed puuduvad',
  carousel: {
    prev: 'Eelmine visuaalne',
    next: 'Järgmine visuaalne',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}',
    },
  },
  calendar: {
    moreEvents: '{0} veel',
  },
  input: {
    clear: 'Clear {0}',
    prependAction: '{0} prepended action',
    appendAction: '{0} appended action',
  },
  fileInput: {
    counter: '{0} faili',
    counterSize: '{0} faili (kokku {1})',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      root: 'Pagination Navigation',
      next: 'Järgmine lehekülg',
      previous: 'Eelmine lehekülg',
      page: 'Mine lehele {0}',
      currentPage: 'Praegune leht, leht {0}',
      first: 'First page',
      last: 'Last page',
    },
  },
  rating: {
    ariaLabel: {
      item: 'Rating {0} of {1}',
    },
  },
  loading: 'Loading...',
  infiniteScroll: {
    loadMore: 'Load more',
    empty: 'No more',
  },
}
