export default {
  badge: 'Značka',
  close: 'Zapri',
  dataIterator: {
    noResultsText: 'Ni iskanega zapisa',
    loadingText: 'Nalaganje...',
  },
  dataTable: {
    itemsPerPageText: 'Vrstic na stran:',
    ariaLabel: {
      sortDescending: 'Razvrščeno padajoče.',
      sortAscending: 'Razvrščeno naraščajoče.',
      sortNone: 'Ni razvrščeno.',
      activateNone: 'Aktivirajte za odstranitev razvrščanja.',
      activateDescending: 'Aktivirajte za padajoče razvrščanje.',
      activateAscending: 'Aktivirajte za naraščajoče razvrščanje.',
    },
    sortBy: 'Razvrsti po',
  },
  dataFooter: {
    itemsPerPageText: 'Predmetov na stran:',
    itemsPerPageAll: 'Vse',
    nextPage: 'Naslednja stran',
    prevPage: 'Prejšnja stran',
    firstPage: 'Prva stran',
    lastPage: 'Zadnja stran',
    pageText: '{0}-{1} od {2}',
  },
  datePicker: {
    itemsSelected: '{0} izbrano/-ih',
    nextMonthAriaLabel: 'Naslednji mesec',
    nextYearAriaLabel: 'Naslednje leto',
    prevMonthAriaLabel: 'Prejšnji mesec',
    prevYearAriaLabel: 'Prejšnje leto',
  },
  noDataText: 'Ni podatkov',
  carousel: {
    prev: 'Prejšnji prikaz',
    next: 'Naslednji prikaz',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}',
    },
  },
  calendar: {
    moreEvents: 'Še {0}',
  },
  input: {
    clear: 'Clear {0}',
    prependAction: '{0} prepended action',
    appendAction: '{0} appended action',
  },
  fileInput: {
    counter: '{0} datotek',
    counterSize: '{0} datotek ({1} skupno)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      root: 'Navigacija po strani po strani',
      next: 'Naslednja stran',
      previous: 'Prejšnja stran',
      page: 'Pojdi na stran {0}',
      currentPage: 'Trenutna stran, stran {0}',
      first: 'First page',
      last: 'Last page',
    },
  },
  rating: {
    ariaLabel: {
      item: 'Rating {0} of {1}',
    },
  },
  loading: 'Loading...',
  infiniteScroll: {
    loadMore: 'Load more',
    empty: 'No more',
  },
}
